import {CircularProgressProps, styled} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import {ComponentType} from 'react';

import {shouldNotForwardProp} from 'utils/styled';

const SRoot = styled(
  'div',
  shouldNotForwardProp('inline', 'padding')
)<{inline?: boolean; padding: number}>(({inline, padding}) => ({
  padding,
  display: inline ? 'inline-flex' : 'flex',
  flexGrow: 1,
  alignItems: 'center',

  justifyContent: 'center',
  height: '100%',
}));

const SProgress = styled(
  CircularProgress as ComponentType<Omit<CircularProgressProps, 'color'>>,
  shouldNotForwardProp('color')
)<{color?: string}>(({theme, color}) => ({
  color: color || theme.palette.background.black, //
}));

// TODO: Remove padding prop, make inline and color='inherit' as default
const LoadingIndicator = ({
  color,
  size = 36,
  inline,
  padding = 20,
  className,
}: {
  inline?: boolean;
  size?: string | number | undefined;
  color?: string;
  padding?: number;
  className?: string;
}) => {
  return (
    <SRoot className={className} inline={inline} padding={padding}>
      <SProgress size={size} color={color} />
    </SRoot>
  );
};

export default LoadingIndicator;
