import {pcaInstance, SSO_SCOPES} from 'core/api/ssoAuth';

export const ssoLogout = () => {
  pcaInstance.logoutRedirect();
};

export const refreshSSOToken = async () => {
  const account = pcaInstance.getAllAccounts()[0];

  const accessTokenRequest = {
    scopes: SSO_SCOPES,
    account: account,
  };

  try {
    const response = await pcaInstance.acquireTokenSilent(accessTokenRequest);
    return response.idToken;
  } catch (error) {
    pcaInstance.loginRedirect(accessTokenRequest);
    return null;
  }
};
