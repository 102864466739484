const {version} = require('../../../package.json');

export enum EnnablEnv {
  development = 'development',
  production = 'production',
}

export const ENV_DEV_URL = 'https://dev.ennabl-test.com';

export const MIN_ALLOWED_YEAR = 1900;

export const APP_VERSION = version;

export const NO_VALUE_CELL = '–';

export const IS_TOUCH_DEVICE = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
