import {Configuration, PublicClientApplication} from '@azure/msal-browser';

import {LocalStorageKey, SsoTenantConfig} from 'core/types';

import {setIsSsoInitialized} from 'core/stores/auth';
import {API_HOST, IS_DEV_ENV, SSO_TENANT_CONFIG_URL} from 'config';

async function fetchSsoTenantConfig(): Promise<SsoTenantConfig> {
  const response = await fetch(`${API_HOST}${SSO_TENANT_CONFIG_URL}`);
  return response.json();
}

export const SSO_SCOPES = ['openid', 'profile', 'email'];
export let pcaInstance: PublicClientApplication;
const SSO_PATHS = ['sso', 'fnb'];

// the function definition has to be in this file because it's used below
// and webpack build is not able to resolve the import from the other file for some reason
export const getIsSSO = () => {
  const firstPath = window.location.hostname.split('.')[0];

  if (IS_DEV_ENV && firstPath !== 'sso') {
    return !!localStorage.getItem(LocalStorageKey.IsSSO);
  }

  return SSO_PATHS.includes(firstPath);
};

(async () => {
  if (!getIsSSO()) {
    return;
  }

  const configData = await fetchSsoTenantConfig();
  const configuration: Configuration = {
    auth: {
      clientId: configData.clientId,
      authority: configData.authority,
      redirectUri: window.location.origin,
    },
  };

  pcaInstance = new PublicClientApplication(configuration);
  setIsSsoInitialized(true);
})();
