import {ReactNode, useRef} from 'react';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import {colorVariables} from 'core/constants';
import {useElementSize} from 'utils/hooks';
import {shouldNotForwardProp} from 'utils/styled';

export type TabPlaceholderProps = {
  heading: string;
  subheading: ReactNode;
  children: ReactNode;
  footer?: ReactNode;
  /**
   * Placeholder located within container. it is not replacing it
   */
  inline?: boolean;
  className?: string;
};

const SRoot = styled('div')`
  height: inherit;
  /* don't allow content to go outside parent */
  overflow-y: hidden;
  flex: 1;
`;

const SContainer = styled(
  'div',
  shouldNotForwardProp('inline')
)<{
  inline?: boolean;
}>(({inline}) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  overflow: 'hidden',

  ...(!inline && {
    border: `1px solid ${colorVariables.Global.border}`,
    borderRadius: 24,
  }),
}));

function getStyles(width: number, height: number) {
  if (width < 1280) {
    return {gap: 16, padding: '16px 0'};
  }

  if (width < 1440) {
    if (height < 250) {
      return {gap: 16, padding: '16px 0'};
    }

    return {gap: 32, padding: '32px 0'};
  }

  if (height < 500) {
    return {gap: 16, padding: '16px 0'};
  }

  return {gap: 28, padding: '70px 0'};
}

export default function TabPlaceholder({
  heading,
  subheading,
  children,
  inline = false,
  footer,
  className,
}: TabPlaceholderProps) {
  const rootRef = useRef<HTMLDivElement>(null);
  const {width, height} = useElementSize(rootRef);

  return (
    <SRoot ref={rootRef}>
      <SContainer inline={inline} className={className} style={getStyles(width, height)}>
        {children}
        <Typography variant='command-main-page-heading' color='command.blueBoldness.600'>
          {heading}
        </Typography>
        <Typography variant='command-standard' color='command.blueBoldness.600'>
          {subheading}
        </Typography>
        {footer}
      </SContainer>
    </SRoot>
  );
}
