import {MsalProvider} from '@azure/msal-react';
import {PropsWithChildren} from 'react';

import {pcaInstance} from 'core/api/ssoAuth';
import {useAuthStore} from 'core/stores/auth';

import {SSOAuthProviderInternal} from './SsoAuthProviderInternal';

export const SsoAuthProvider = ({children}: PropsWithChildren) => {
  const isSsoInitialized = useAuthStore(store => store.isSsoInitialized);

  if (!isSsoInitialized) {
    return children;
  }

  return (
    <MsalProvider instance={pcaInstance}>
      <SSOAuthProviderInternal>{children}</SSOAuthProviderInternal>
    </MsalProvider>
  );
};
