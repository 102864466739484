import {colorVariablesNext} from 'core/constants';
import {integerFormatter} from 'utils/formatters';

/**
 * field names are corresponds with OpportunityKpi
 */
export const opportunitiesKpiMap = {
  crossSell: {
    label: 'Cross Sell',
    key: 'crossSell',
    formatValue: integerFormatter,
    group: 'opportunities-detailed',
    testId: 'cross-sell',
  },
  monoline: {
    label: 'Monoline',
    key: 'monoline',
    formatValue: integerFormatter,
    group: 'opportunities-detailed',
    testId: 'monoline',
  },
  total: {
    label: 'Opportunities',
    key: 'total',
    formatValue: integerFormatter,
    group: 'opportunities',
    testId: 'opportunities',
  },
  winBack: {
    label: 'Win Back',
    key: 'winBack',
    formatValue: integerFormatter,
    group: 'opportunities-detailed',
    testId: 'win-back',
  },
} as const;

export const BE_MAX_PAGINATION_LIMIT = 10000;

export const INTERMEDIARY_EMPTY = 'Intermediary - Empty';
export const ACCOUNT_MANAGER_EMPTY = 'Account Manager - Empty';

export const PRODUCER_EMPTY = 'Producer - Empty';
export const PRODUCER_FALLBACK_ID = 'producer-';
export const EMPTY_ID_SUFFIX = 'dummy-id-';

export const ACCOUNT_EXECUTIVE_EMPTY = 'Account executive - Empty';
export const ACCOUNT_EXECUTIVE_FALLBACK_ID = 'account-executive-';

/**
 * this is a query param
 */
export const SEARCH_QUERY = 'searchQuery';

export const percentileToTitleMap = {
  bottom25: 'Bottom 25th',
  middle50: 'Middle 50',
  top25: 'Top 25th',
};

export const bandIdToColorIndexMap: Record<string, string> = {
  small: colorVariablesNext.dataTypes.Accounts[80],
  medium: colorVariablesNext.dataTypes.Accounts[60],
  large: colorVariablesNext.dataTypes.Accounts[30],
};

export const LIMIT = {
  DEFAULT_LIMIT: 5,
  SHOW_MORE_LIMIT: 1000,
} as const;

export const SUPPORT_EMAIL = 'support@ennabl.com';

export const LIMIT_ERROR_CODE = 402;

export const CUSTOM_REPORT_GROUP_ID = 'CUSTOM_AGGREGATED';

// we have to use something different from slash, because our entity ids could have a slash
export const SUB_TAB_DELIMITER = '::';

export const nullableKeysForApolloResponse = ['timestamp'];
