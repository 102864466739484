import {useMsal} from '@azure/msal-react';
import {PropsWithChildren, useEffect} from 'react';
import {InteractionStatus} from '@azure/msal-browser';

import {setTokenAndAuthorized} from 'core/stores/auth';
import {SSO_SCOPES} from 'core/api/ssoAuth';

export const SSOAuthProviderInternal = ({children}: PropsWithChildren) => {
  const {instance, accounts, inProgress} = useMsal();
  const token = accounts[0]?.idToken;

  useEffect(() => {
    if (token) {
      setTokenAndAuthorized(token);
    }
  }, [token]);

  useEffect(() => {
    if (inProgress !== InteractionStatus.None) {
      return;
    }

    if (accounts.length === 0) {
      instance.loginRedirect({scopes: SSO_SCOPES});
      return;
    }

    const timerId = setInterval(() => {
      instance.acquireTokenSilent({
        account: accounts[0],
        scopes: SSO_SCOPES,
      });
    }, 1000 * 60 * 10);

    return () => {
      clearInterval(timerId);
    };
  }, [accounts, instance, inProgress]);

  return children;
};
