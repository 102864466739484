import {Link} from 'react-router-dom';
import {circularProgressClasses, styled, Typography, TypographyProps} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import {ButtonHTMLAttributes, ForwardedRef, forwardRef, ReactNode, useMemo} from 'react';

import {colorVariables, styleVariables} from 'core/constants';
import {shouldNotForwardProp} from 'utils/styled';

const CONTAINED = 'contained';
const OUTLINED = 'outlined';
const TEXT = 'text';

const BIG = 'big';

const PRIMARY = 'primary';
const GREY = 'grey';

type Variant = 'contained' | 'outlined' | 'text';
export type Size = 'medium' | 'big';
type Color = 'primary' | 'secondary' | 'red' | 'grey';

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: Variant;
  size?: Size;
  /**
   * Forcibly active state for button
   */
  active?: boolean;
  /**
   * pass icon as a single child
   */
  iconOnly?: boolean;
  fullWidth?: boolean;
  color?: Color;
  loading?: boolean;
  /**
   * Icon located BEFORE the text
   */
  startIcon?: ReactNode;
  /**
   * Icon located AFTER the text
   */
  endIcon?: ReactNode;
  noWrap?: boolean;
  to?: string;
  href?: string;
  /**
   * Override label props
   */
  TypographyProps?: TypographyProps;
};

const SButton = styled(
  'button',
  shouldNotForwardProp('padding', 'variant', 'color', 'loading', 'active', 'noWrap', 'fullWidth')
)<{
  padding?: string;
  variant: Variant;
  color: Color;
  loading?: boolean;
  active?: boolean;
  noWrap?: boolean;
  fullWidth?: boolean;
}>(({noWrap, fullWidth, active, loading, padding, variant, color}) => ({
  userSelect: 'none',
  outline: 'none',

  paddingLeft: 12,
  paddingRight: 12,

  borderRadius: `${styleVariables.borderRadiusRegular}px`,

  /* using 'flex' is wrong for the button, because it tries to stretch to parent width
   but button shouldn't do that w/o 'fullWidth' prop */
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 8,

  /* for loading indicator */
  position: 'relative',
  padding,

  ':not(:disabled)': {
    cursor: 'pointer',
  },

  ...(loading && {
    '& > :not(.button-circular-progress)': {
      visibility: 'hidden',
    },
  }),

  ...(variant === CONTAINED && {
    border: 'none',

    ...(color === PRIMARY && {
      color: colorVariables.Global['text-primary-default'],
      backgroundColor: colorVariables.Global[active ? 'background-primary-active' : 'background-primary'],

      ['&:not(:disabled)']: {
        ['&:hover, &:focus-visible']: {
          backgroundColor: colorVariables.Global['background-primary-hover'],
        },
      },

      '&:active': {
        backgroundColor: colorVariables.Global['background-primary-active'],
      },

      '&:disabled': {
        color: colorVariables.Global['text-primary-default'],
        backgroundColor: colorVariables.Global[loading ? 'background-primary-active' : 'background-disabled'],
      },
    }),

    ...(active &&
      color === 'red' && {
        backgroundColor: colorVariables.Global['background-primary-active'],
      }),
  }),

  ...(variant === OUTLINED && {
    borderWidth: 1,
    borderStyle: 'solid',

    ...(color === PRIMARY && {
      color: colorVariables.Global['text-secondary'],
      backgroundColor: colorVariables.Global[active ? 'background-secondary-active' : 'background-secondary'],
      borderColor: colorVariables.Global[active ? 'border-active' : 'border'],

      ['&:not(:disabled)']: {
        '&:hover, &:focus-visible': {
          backgroundColor: colorVariables.Global['background-secondary-hover'],
          borderColor: colorVariables.Global['border-active'],
        },
      },

      '&:active': {
        backgroundColor: colorVariables.Global['background-secondary-active'],
      },

      '&:disabled': {
        color: colorVariables.Global['text-secondary-disabled'],
        backgroundColor:
          colorVariables.Global[loading ? 'background-secondary-active' : 'background-secondary-disabled'],
        borderColor: colorVariables.Global['border-disabled'],

        ...(loading && {
          border: '1px solid transparent',
        }),
      },
    }),

    ...(color === 'red' && {
      color: colorVariables.Global['text-secondary-danger'],
      backgroundColor: colorVariables.Global[active ? 'background-secondary-active-danger' : 'background-secondary'],
      borderColor: colorVariables.Global[active ? 'border-danger' : 'border'],

      ['&:not(:disabled)']: {
        '&:hover, &:focus-visible': {
          backgroundColor: colorVariables.Global['background-secondary-hover'],
          borderColor: colorVariables.Global['border-danger'],
        },
      },

      '&:active': {
        backgroundColor: colorVariables.Global['background-secondary-active-danger'],
      },

      '&:disabled': {
        color: colorVariables.Global['text-secondary-disabled-danger'],
        backgroundColor:
          colorVariables.Global[loading ? 'background-secondary-active-danger' : 'background-secondary-disabled'],
        borderColor: colorVariables.Global['border-secondary-danger'],

        ...(loading && {
          border: '1px solid transparent',
        }),
      },
    }),

    ...(color === GREY && {
      color: active ? colorVariables.Global['text-secondary'] : styleVariables.colorPalette.coolGrey(600),
      backgroundColor: colorVariables.Global[active ? 'background-secondary-active' : 'background-secondary'],
      borderColor: colorVariables.Global[active ? 'border-active' : 'border'],

      ['&:not(:disabled)']: {
        '&:hover, &:focus-visible': {
          color: colorVariables.Global['text-secondary'],
          backgroundColor: colorVariables.Global['background-secondary-hover'],
          borderColor: colorVariables.Global['border-active'],
        },
      },

      '&:active': {
        color: colorVariables.Global['text-secondary'],
        backgroundColor: colorVariables.Global['background-secondary-active'],
      },

      '&:disabled': {
        color: colorVariables.Global['text-secondary-disabled'],
        backgroundColor: colorVariables.Global['background-secondary-disabled'],
        borderColor: colorVariables.Global['border-disabled'],
      },
    }),
  }),

  ...(variant === TEXT && {
    border: 'none',
    backgroundColor: 'transparent',

    ...(color === PRIMARY && {
      color: colorVariables.Global[active ? 'text-tertiary-active' : 'text-tertiary'],

      ['&:not(:disabled)']: {
        '&:hover, &:focus-visible': {
          color: colorVariables.Global['text-tertiary-hover'],
        },
      },

      '&:active': {
        color: colorVariables.Global['text-tertiary-active'],
      },

      '&:disabled': {
        color: colorVariables.Global['text-tertiary-disabled'],
      },
    }),

    ...(color === 'red' && {
      color: colorVariables.Global[active ? 'text-tertiary-active-danger' : 'text-tertiary-danger'],

      ['&:not(:disabled)']: {
        '&:hover, &:focus-visible': {
          color: colorVariables.Global['text-tertiary-hover-danger'],
        },
      },

      '&:active': {
        color: colorVariables.Global['text-tertiary-active-danger'],
      },

      '&:disabled': {
        color: colorVariables.Global['text-tertiary-disabled-danger'],
      },
    }),
  }),

  ...(noWrap && {
    whiteSpace: 'nowrap',
  }),

  ...(fullWidth && {
    width: '100%',
  }),
}));

const STypography = styled(Typography)(() => ({
  /* required for svg icons to not break layout */
  display: 'flex',
}));

const paddingMap = {
  big: '10px 24px',
  bigIconOnly: '10px',
  medium: '8px 16px',
  mediumIconOnly: '6px',
  bigOutlined: '9px 23px',
  mediumOutlined: '7px 15px',
  bigOutlinedIconOnly: '9px',
  mediumOutlinedIconOnly: '5px',
};

const getPadding = (size: Size, variant: Variant, iconOnly: boolean) => {
  const variantKey = variant === OUTLINED ? 'Outlined' : '';
  const iconOnlyKey = iconOnly ? 'IconOnly' : '';

  return paddingMap[`${size}${variantKey}${iconOnlyKey}`];
};

const SCircularProgress = styled(
  CircularProgress,
  shouldNotForwardProp('buttonVariant', 'circleColor')
)<{buttonVariant: Variant; circleColor: Color}>(({buttonVariant, circleColor}) => ({
  visibility: 'visible',
  position: 'absolute',
  left: 0,
  right: 0,
  margin: '0 auto',

  [`.${circularProgressClasses.circle}`]: {
    ...(buttonVariant === CONTAINED && {
      color: colorVariables.Global['icon-primary'],
    }),

    ...(buttonVariant === OUTLINED && {
      ...(circleColor === PRIMARY && {
        color: colorVariables.Global['icon-secondary'],
      }),

      ...(circleColor === 'red' && {
        color: colorVariables.Global['icon-tertiary-active-danger'],
      }),
    }),

    ...(buttonVariant === TEXT && {
      ...(circleColor === PRIMARY && {
        color: colorVariables.Global['icon-tertiary-active'],
      }),

      ...(circleColor === 'red' && {
        color: colorVariables.Global['icon-tertiary-danger'],
      }),
    }),
  },
}));

const SLink = SButton.withComponent(Link);
const SA = SButton.withComponent('a');

/**
 @deprecated - use @mui/material Button instead
 */
const BaseButton = (
  {
    active = false,
    iconOnly = false,
    disabled = false,
    loading = false,
    variant = CONTAINED,
    color = PRIMARY,
    size = BIG,
    fullWidth = false,
    type = 'button',
    children,
    startIcon = null,
    endIcon = null,
    noWrap = false,
    to,
    href,
    TypographyProps = {},
    ...props
  }: ButtonProps,
  ref: ForwardedRef<HTMLElement>
) => {
  const Component = to ? SLink : href ? SA : SButton;

  const linkOrButtonProps = useMemo(() => {
    if (to) {
      return {
        to,
      };
    }

    if (href) {
      return {
        href,
      };
    }

    return {
      'aria-disabled': disabled || loading,
      disabled: disabled || loading,
      type,
    };
  }, [to, href, disabled, loading, type]);

  return (
    <Component
      ref={ref as any}
      {...(linkOrButtonProps as any)}
      padding={getPadding(size, variant, iconOnly)}
      variant={variant}
      color={color}
      loading={loading}
      active={active}
      noWrap={noWrap}
      fullWidth={fullWidth}
      tabIndex={0}
      {...props}
    >
      {startIcon}
      {loading && (
        <SCircularProgress
          disableShrink
          size={16}
          buttonVariant={variant}
          circleColor={color}
          classes={{
            root: 'button-circular-progress',
          }}
        />
      )}
      <STypography variant={size === 'medium' ? 'command-medium-text' : 'command-button-text'} {...TypographyProps}>
        {children}
      </STypography>
      {endIcon}
    </Component>
  );
};

/**
  @deprecated - use @mui/material Button instead
 */
const Button = forwardRef(BaseButton);

export default Button;
