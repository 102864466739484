import {PropsWithChildren} from 'react';

import {getIsSSO} from 'core/api/ssoAuth';

import {Auth0Provider} from './Auth0Provider';
import {SsoAuthProvider} from './SsoAuthProvider/SsoAuthProvider';

export const AuthProvider = ({children}: PropsWithChildren) => {
  const isSSO = getIsSSO();

  if (isSSO) {
    return <SsoAuthProvider>{children}</SsoAuthProvider>;
  }

  return <Auth0Provider>{children}</Auth0Provider>;
};
