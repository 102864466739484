import {isUndefined} from 'lodash/fp';

import {FeatureFlag, LocalStorageKey} from 'core/types';
import {FeatureFlagData, ModifyState} from 'modules/modifier/types';

import {parseJSON} from 'utils/json';

export const getModifiers = () =>
  parseJSON<{state?: ModifyState}>(localStorage.getItem(LocalStorageKey.Modifiers), {})?.state?.modifiers ?? [];

export const getAvailableModifiers = (
  allFlags: FeatureFlag[] | undefined,
  modifiers: Record<string, FeatureFlagData>
) => {
  if (isUndefined(allFlags)) {
    return {available: [], unavailable: []};
  }

  const availableModifiers: Record<string, FeatureFlagData> = {};
  const unavailableModifiers: Record<string, FeatureFlagData> = {};
  Object.entries(modifiers).forEach(([key, flagData]) => {
    if (allFlags.find(flag => flag?.name === key)) {
      availableModifiers[key] = flagData;
    } else {
      unavailableModifiers[key] = flagData;
    }
  });

  return {
    available: Object.keys(availableModifiers).length ? availableModifiers : null,
    unavailable: Object.keys(unavailableModifiers).length ? unavailableModifiers : null,
  };
};
