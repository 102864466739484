import {css, Global} from '@emotion/react';
import 'normalize.css/normalize.css';
import 'components/Tree/tree.css';
import {autocompleteClasses} from '@mui/material';
import {paperClasses} from '@mui/material/Paper';

import {backgroundColor, letterSpacingRegular, scrollbarColor, textColor} from 'core/constants/styleVariables';

const globalStyles = css`
  * {
    box-sizing: border-box;
    scrollbar-color: ${scrollbarColor} transparent;
    scrollbar-width: auto;
  }

  *::-webkit-scrollbar {
    width: 16px;
    height: 16px;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 8px;
    min-height: 35px;
    border: 4px solid transparent;
    background-clip: padding-box;
    overflow: hidden;
    background-color: ${scrollbarColor};
  }

  *::-webkit-scrollbar-thumb:hover {
    background-color: hsl(228, 31%, 71%);
  }

  body {
    background-color: ${backgroundColor};
    color: ${textColor};
    margin: 0;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
      'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 14px;
    letter-spacing: ${letterSpacingRegular}px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  @media print {
    body {
      -webkit-print-color-adjust: exact;
      -moz-print-color-adjust: exact;
      -ms-print-color-adjust: exact;
      print-color-adjust: exact;

      &.print-mode {
        & > #print-container {
          display: block;
          width: 100%;
        }

        & > *:not(#print-container) {
          display: none;
          visibility: hidden;
        }
      }
    }

    @page {
      /* size: A4 portrait; */
      -webkit-print-color-adjust: exact;
    }
  }

  a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
  }

  a:active,
  a:hover {
    text-decoration: none;
  }

  #root {
    max-height: -webkit-fill-available;
    max-height: -moz-fill-available;
  }

  [data-sonner-toast] [data-title] {
    font-weight: 400;
  }

  // Fix for MUI Autocomplete popper not hiding when empty
  .${autocompleteClasses.popper}:has(.${paperClasses.root}:empty) {
    display: none !important;
  }
`;

export const RootStyles = () => <Global styles={globalStyles} />;
