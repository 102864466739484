import {identity, isNumber, isBoolean, isString} from 'lodash/fp';
import {isPossiblePhoneNumber, isValidPhoneNumber, parsePhoneNumber} from 'libphonenumber-js';

import {NO_VALUE_CELL} from 'core/constants/globalVariables';

export const numberFormatter = (val: number, options?: Intl.NumberFormatOptions): string =>
  new Intl.NumberFormat('en-US', {...options, style: 'decimal'}).format(val);

export const integerFormatter = (value: number) => numberFormatter(value, {maximumFractionDigits: 0});

export const percentFormatter = (val: number, fraction = 2): string =>
  new Intl.NumberFormat('en-US', {
    style: 'percent',
    maximumFractionDigits: fraction,
  }).format(val);

export const percentFormatterInteger = (val: number): string =>
  new Intl.NumberFormat('en-US', {
    style: 'percent',
    maximumFractionDigits: 0,
  }).format(val);

/**
 * Will give you stuff like this: '$500,000.00'
 */
export const currencyFormatter = (val: number): string =>
  new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(val / 100);

const currencyFormatterBase = (val: number | string, fraction = 0, skipCents = false): string => {
  const value = skipCents ? Number(val) / 100 : Number(val);

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: fraction,
  }).format(value);
};

/**
 * Will give you stuff like this: '$500,000'(for 50'000'000)
 */
export const currencyFormatterSkipCents = (val: number | string, fraction = 0): string =>
  currencyFormatterBase(val, fraction, true);

/**
 * Will give you stuff like this: '$500,000'(for 500'000)
 */
export const currencyFormatterForDollars = (val: number | string, fraction = 0): string =>
  currencyFormatterBase(val, fraction);

export type FormatterFn = (val: number) => string | number;

export const groupingNumberFormatterFactory =
  (formatter: FormatterFn = identity, preProcessor: (val: number) => number = identity, decimalPlaces: number = 0) =>
  (val: number) => {
    if (Number.isNaN(val) || val === undefined) {
      return val;
    }

    if (val === 0) {
      return formatter(0);
    }

    const sign = Math.sign(val);
    const unsigned = Math.abs(val);
    const preVal = preProcessor(unsigned);

    if (preVal < 1_000 * Math.pow(10, decimalPlaces)) {
      return formatter(preVal * sign);
    }

    // !== '1000' to avoid 1000k
    if (
      preVal < 1_000_000 * Math.pow(10, decimalPlaces) &&
      Math.round(preVal / Math.pow(10, decimalPlaces) / 1000) !== 1000
    ) {
      return `${formatter(Math.round(preVal / 1000) * sign)}k`;
    }

    if (preVal < 10_000_000 * Math.pow(10, decimalPlaces)) {
      return `${formatter(Math.round(preVal / 1000000) * sign)}M`;
    }

    if (
      preVal < 1_000_000_000 * Math.pow(10, decimalPlaces) &&
      Math.round(preVal / Math.pow(10, decimalPlaces) / 1000000) !== 1000
    ) {
      return `${formatter(Math.round(preVal / 1000000) * sign)}M`;
    }

    if (preVal < 1_000_000_000_000 * Math.pow(10, decimalPlaces)) {
      return `${formatter(Math.round(preVal / 1000000000) * sign)}B`;
    }

    // keep in mind that we don't apply formatter here, so it won't
    // be $1T+ by itself
    return `${sign}T+`;
  };

/**
 * Will give you stuff like this: '8M' or '800K'
 */
export const numberGroupingFormatter = (val: number) => groupingNumberFormatterFactory()(val) as string;

/**
 * If decimalPlaces = 0 then '8K', else if decimalPlaces = 1 then '8.1K'
 */
export const numberGroupingFormatterWithOneDecimalPlaces = (val: number) =>
  groupingNumberFormatterFactory(
    val => `${val / Math.pow(10, 1)}`,
    val => val * Math.pow(10, 1),
    1
  )(val);

function getCurrencyDecimalPlaces(value: number | undefined, fractionDigits?: number) {
  if (isNumber(value)) {
    if (value < 1_000_00) {
      return 0;
    }

    if (fractionDigits) {
      return fractionDigits;
    }

    if (value > 1_000_000_00) {
      return 2;
    }
  }

  return 1;
}

export const currencyWithAbbreviation = (value: number, fractionDigits?: number) => {
  const decimalPlaces = getCurrencyDecimalPlaces(Math.abs(value), fractionDigits);

  return groupingNumberFormatterFactory(
    val => currencyFormatterSkipCents(val, decimalPlaces),
    val => val,
    2
  )(value) as string;
};

const abbreviationMap = {
  k: 1_000,
  M: 1_000_000,
  B: 1_000_000_000,
};

export const currencyWithAbbreviationGetNumber = (value: string): number => {
  const abbreviation = (isNumber(value.slice(-1)) ? '' : value.slice(-1)) as keyof typeof abbreviationMap;
  const number = parseFloat(value.slice(1).replace(/,/g, ''));

  return number * (abbreviationMap[abbreviation] || 1);
};

export const trendFormatter = (value: number) => (value > 0 ? '↑' : '↓');

export const bandFormatter = (
  min: number | undefined | null,
  max: number | undefined | null,
  formatter: FormatterFn = currencyWithAbbreviation
) => {
  if (min && max) {
    return `${formatter(min)} – ${formatter(max)}`;
  }

  if (min) {
    return `>${formatter(min)}`;
  }

  if (max) {
    return `<${formatter(max)}`;
  }
};

export function revenueBandFormatter({min, max}: {min?: number | null; max?: number | null}) {
  const minIsNumber = isNumber(min);
  const maxIsNumber = isNumber(max);

  if (minIsNumber && maxIsNumber) {
    return `${currencyFormatterSkipCents(min)} - ${currencyFormatterSkipCents(max)}`;
  }

  if (!minIsNumber && maxIsNumber) {
    return `Below ${currencyFormatterSkipCents(max)}`;
  }

  if (!maxIsNumber && minIsNumber) {
    return `Above ${currencyFormatterSkipCents(min)}`;
  }

  return '–';
}

/**
 * Adds prefix with '+' sign to your formatted numbers
 */
export const forceSignFormatter = (formatter: FormatterFn) => (value: number) => {
  const sign = Math.sign(value) >= 0 ? '+' : '';
  return `${sign}${formatter(value)}`;
};

const pluralRules = new Intl.PluralRules('en-US');

/**
 * Simple pluralization util
 *
 * @thanks https://2ality.com/2019/12/intl-pluralrules.html#a-simple-tool-function-for-pluralization
 */
export function pluralize(count: number, singular: string, plural: string) {
  const grammaticalNumber = pluralRules.select(count);

  switch (grammaticalNumber) {
    case 'one':
      return singular;
    case 'other':
      return plural;
    default:
      throw new Error('Unknown: ' + grammaticalNumber);
  }
}

const ordinalRules = new Intl.PluralRules('en', {type: 'ordinal'});
const suffixes = new Map([
  ['one', 'st'],
  ['two', 'nd'],
  ['few', 'rd'],
  ['other', 'th'],
]);

/**
 * This is useful to figure out the ordinal indicator, e.g. "1st", "2nd", "3rd", "4th", "42nd" and so forth.
 * @thanks https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/PluralRules/PluralRules#using_options
 * @param val
 */
export const ordinalSuffixFormatter = (val: number) => {
  const rule = ordinalRules.select(val);
  const suffix = suffixes.get(rule);

  return `${val}${suffix}`;
};

export const numberToRomanFormatter = (value: number) => {
  const rom: {[key: string]: number} = {
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XL: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1,
  };

  return Object.keys(rom).reduce(
    function (acc, ch) {
      acc.str += ch.repeat(acc.value / rom[ch]);
      acc.value %= rom[ch];
      return acc;
    },
    {str: '', value}
  ).str;
};

export function phoneNumberFormatter(value: string) {
  // See unit tests for more understanding why we DO need such parsing
  const normalizedValue = value.replace(/[^a-z0-9\s+]/gi, '');
  const maybeAddPlus = normalizedValue.startsWith('1') ? `+${normalizedValue}` : normalizedValue;

  if (
    // this one expected to pass invalid numbers, like '18850633350' or '11212422211'
    // which are not will be recognized by `isValidPhoneNumber`
    isPossiblePhoneNumber(maybeAddPlus) ||
    // that one must guard against bad 'ext' numbers and completely non-US numbers
    isValidPhoneNumber(maybeAddPlus, 'US')
  ) {
    const parsed = parsePhoneNumber(maybeAddPlus, 'US').formatInternational();
    const [phone, ext] = parsed.split('ext.');
    const withExt = ext ? ` ext.${ext}` : '';
    return `${phone.replace(/\s/g, '').replace(/^(\+\d)(\d{3})(\d{3})(\d{4})$/, '$1 ($2) $3-$4')}${withExt}`;
  }
}

/**
 * Some of our integration services like HazardHub, Fenris validates zipCode by
 * accepting only 4-5 digits string. Enrichment team do that job for our Client types,
 * as for prospects, we have to do it by ourselves
 */
export const zipCodeFormatter = (zipCode: string) => {
  return zipCode.match(/^(\d+)/)?.[0].slice(0, 5) ?? undefined;
};

export const booleanFormatter = (value: string | boolean) => {
  let bool: boolean | null = null;

  if (isBoolean(value)) {
    bool = value;
  }

  if (isString(value)) {
    const lewerCaseValue = value.trim().toLowerCase();
    bool = lewerCaseValue === 'true' ? true : lewerCaseValue === 'false' ? false : null;
  }

  return bool === null ? NO_VALUE_CELL : bool ? 'TRUE' : 'FALSE';
};

export const yearsFormatter = (years: number) => {
  return `${numberFormatter(years, {maximumFractionDigits: 1})} yrs`;
};
