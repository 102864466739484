import {isNil, isObject} from 'lodash/fp';
import {ReactNode} from 'react';

import {SortOrder} from 'types';

import {ALL_ENTITIES_ID} from 'modules/decisionsNext/utils';
import {nullableKeysForApolloResponse} from 'modules/decisionsNext/constants';

// TODO: Rename this file to something less generic

/**
 * Downloads a remote file
 * @param url
 * @param name
 */
export const urlDownload = (url: string, name?: string) => {
  const link = document.createElement('a');
  link.download = name || url;
  link.href = url;
  link.click();
  window.URL.revokeObjectURL(url);
};

export const getSortOrder = (currentSortOrder?: SortOrder, defaultOrder = SortOrder.Asc) => {
  switch (currentSortOrder) {
    case SortOrder.Asc:
      return SortOrder.Desc;
    case SortOrder.Desc:
      return SortOrder.Asc;
    default:
      return defaultOrder;
  }
};

export function getFilterIdsValue(entityId?: string) {
  return entityId && entityId !== ALL_ENTITIES_ID ? [entityId] : undefined;
}

export function getFilterIdsStringValue(entityId?: string) {
  return entityId && entityId !== ALL_ENTITIES_ID ? entityId : undefined;
}

export function getLabelByValue(options: {label: ReactNode; value?: any}[], value?: any) {
  return options.find(option => option.value === value)?.label;
}

export const normalizeInsuranceSegment = (key: string) => {
  switch (key) {
    case 'Benefit':
      return 'Benefits';
    default:
      return key;
  }
};

export function generateExternalLink(link: string) {
  return link.startsWith('http') ? link : `https://${link}`;
}

/**
 * Utility type that deeply converts all nulls to undefineds,
 * useful when normalizing data from GQL API to strip off useless
 * nulls.
 */
export type NullsToUndefineds<T> = T extends null
  ? undefined
  : T extends Date
  ? T
  : {
      [K in keyof T]: T[K] extends (infer U)[] ? NullsToUndefineds<U>[] : NullsToUndefineds<T[K]>;
    };

/**
 * Recursively replaces all nulls with undefineds.
 * Skips object classes (that have a `.__proto__.constructor`).
 *
 * Unfortunately, until https://github.com/apollographql/apollo-client/issues/2412
 * gets solved at some point,
 * this is the only workaround to prevent `null`s going into the codebase,
 * if it's connected to a Apollo server/client.
 */
export function replaceNullsWithUndefineds<T>(target: T): NullsToUndefineds<T> {
  if (isNil(target)) {
    return undefined as NullsToUndefineds<T>;
  }

  if (!isObject(target)) {
    return target as NullsToUndefineds<T>;
  }

  const result: any = {};

  Object.keys(target).forEach(key => {
    const value = (target as any)[key];

    if (isNil(value)) {
      result[key] = undefined;
    } else if (isObject(value)) {
      result[key] = replaceNullsWithUndefineds(value);
    } else {
      result[key] = value;
    }
  });

  return result;
}

export type CleanResponseWithNulls<T> = T extends Record<string, unknown>
  ? Omit<
      {
        [K in keyof T]: CleanResponseWithNulls<T[K]>;
      },
      '__typename'
    >
  : T;

export type CleanResponse<T> = T extends null
  ? undefined
  : T extends Date
  ? T
  : Omit<
      {
        [K in keyof T]: T[K] extends (infer U)[] ? NullsToUndefineds<U>[] : NullsToUndefineds<T[K]>;
      },
      '__typename'
    >;

export function cleanApolloResponse<T>(response: T): CleanResponse<T> {
  if (Array.isArray(response)) {
    return response.map(cleanApolloResponse) as CleanResponse<T>;
  } else if (isObject(response)) {
    return Object.fromEntries(
      Object.entries(response).flatMap(([key, value]) => {
        if (key === '__typename' || (isNil(value) && !nullableKeysForApolloResponse.includes(key))) {
          return [];
        }

        return [[key, isObject(value) ? cleanApolloResponse(value) : value]];
      })
    ) as CleanResponse<T>;
  } else {
    return response as CleanResponse<T>;
  }
}

export const defaultTabMatcher = (url: string) => {
  // remove trailing slash (a case for prod env, or someone could send such link)
  const urlArr = url.replace(/\/$/, '').split('/');
  return urlArr[urlArr.length - 1];
};

export const getDeepKeys = <T extends object>(obj: T) => {
  const keys: string[] = [];

  function getKeys(obj: T, path: string) {
    const innerKeys = Object.keys(obj) as (keyof typeof obj)[];

    // this condition covers a case then `obj` can be an empty object
    if (!innerKeys.length && path) {
      keys.push(path);
    } else {
      innerKeys.forEach(key => {
        if (isObject(obj[key])) {
          getKeys(obj[key] as T, String(path ? `${path}.${String(key)}` : key));
        } else {
          keys.push(String(path ? `${path}.${String(key)}` : key));
        }
      });
    }
  }

  getKeys(obj, '');

  return keys;
};
