import {isWebViewApp, logOutWebView} from 'core/utils/webView';
import {getIsSSO} from 'core/api/ssoAuth';

import {auth0Logout, refreshAuth0Token} from './auth0';
import {refreshSSOToken, ssoLogout} from './ssoAuth';

export function logout() {
  if (isWebViewApp()) {
    logOutWebView();
    return;
  }

  if (getIsSSO()) {
    ssoLogout();
    return;
  }

  auth0Logout();
}

export async function refreshAuthToken() {
  if (getIsSSO()) {
    return refreshSSOToken();
  }

  const auth0TokenResponse = await refreshAuth0Token();
  return auth0TokenResponse?.token;
}
