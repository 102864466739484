import {styled} from '@mui/material/styles';
import {Outlet, useNavigate} from 'react-router-dom';
import {memo} from 'react';

import {AppPermissionId} from 'modules/decisionsNext/api/types';

import {useAppPermissions} from 'core/hooks/authHooks';
import {styleVariables} from 'core/constants';

import Button from 'components/Button';
import TabPlaceholder from 'components/TabPlaceholder';

import ProtectionImage from './protection.svg';

const SContainer = styled('div')`
  width: 100vw;
  padding: 16px;
  background-color: ${styleVariables.colorWhite};
  height: 100%;
  text-align: center;
`;

type RequirePermissionProps = {permissionId: AppPermissionId};

export const RequirePermission = memo(function RequirePermission({permissionId}: RequirePermissionProps) {
  const navigate = useNavigate();

  const {hasPermission} = useAppPermissions();

  if (hasPermission(permissionId)) {
    return <Outlet />;
  }

  return (
    <SContainer>
      <TabPlaceholder
        heading='We’re sorry...'
        subheading='The page you’re trying to reach has restricted access. Please refer to your system administrator.'
        footer={
          <Button variant='outlined' onClick={() => navigate(-1)}>
            Go back
          </Button>
        }
      >
        <ProtectionImage height='100%' />
      </TabPlaceholder>
    </SContainer>
  );
});
