import {isObject} from 'lodash';

import {AuthToken} from 'core/types';

import {APP_LOGIN_URI} from 'config';
import {authorize, getAuth0AuthToken} from 'core/api/auth0';
import {getCypressAuthToken, isCypressApp} from 'core/utils/cypress';
import {getWebViewAuthToken, isWebViewApp, refreshWebViewToken} from 'core/utils/webView';

function redirectToLogin() {
  const url = new URL(APP_LOGIN_URI);
  url.searchParams.append('redirectUri', window.location.href);
  window.location.replace(url.toString());
}

export async function getAuth0Token(): Promise<AuthToken | null> {
  const isWebView = isWebViewApp();
  const isCypress = isCypressApp();

  try {
    if (isWebView) {
      return getWebViewAuthToken();
    }

    if (isCypress) {
      return getCypressAuthToken();
    }

    // await is required here to catch the error
    return await getAuth0AuthToken();
  } catch (error) {
    if (isWebView) {
      await refreshWebViewToken();
    }

    if (!isWebView && !isCypress) {
      if (isObject(error) && 'code' in error && error.code === 'consent_required') {
        authorize();
      } else {
        redirectToLogin();
      }
    }

    return null;
  }
}

export async function refreshAuth0Token() {
  if (isWebViewApp()) {
    await refreshWebViewToken();
  }

  return getAuth0Token();
}

export function auth0Logout() {
  const url = new URL(APP_LOGIN_URI);
  url.searchParams.append('logout', 'true');
  url.searchParams.append('redirectUri', window.location.href);

  window.location.replace(url.toString());
}
