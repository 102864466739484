export enum FeatureFlags {
  AccountExecutives = 'ACCOUNT_EXECUTIVES',
  AmsProducerCodes = 'AMS_PRODUCER_CODES',
  CarrierAppetiteCommunityTab = 'CARRIER_APPETITE_COMMUNITY_TAB',
  ClientTagsFilter = 'CLIENT_TAGS_FILTER',
  CompanySearchCountrySelect = 'COMPANY_SEARCH_COUNTRY_SELECT',
  CrossSellProspects = 'CROSS_SELL_PROSPECTS',
  DefaultBizOrgFilterValues = 'DEFAULT_BUSINESS_ORG_FILTERS',
  Documents = 'DOCUMENTS',
  LifetimeValueSection = 'LIFETIME_VALUE_SECTION',
  LocationSearchSeparateRequests = 'LOCATION_SEARCH_SEPARATE_REQUESTS',
  LossRuns = 'LOSS_RUNS',
  O360Score = 'O360_SCORE',
  PolicyStatusFilter = 'POLICY_STATUS_FILTER',
  PolicyTagsFilter = 'POLICY_TAGS_FILTER',
  PolicySourceCodes = 'POLICY_SOURCE_CODES',
  ProducerViewWithCarrierAppetite = 'PRODUCER_VIEW_WITH_CARRIER_APPETITE',
  RevealPlugin = 'REVEAL_PLUGIN',
  RenewalStage = 'RENEWAL_STAGE',
  UnisonTenant = 'UNISON_TENANT',
}
